import React from "react"
import { Link, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import mdxComponents from "../../components/mdx"
import styled, { ThemeProvider } from "styled-components"
import Sidebar from "./components/Sidebar"
import TableOfContent from "./components/TableOfContent"

const THEME = {
  colors: {
    primary: '#8256A6'
  }
}

const Wrapper = styled.div`
  > :first-child {
    position: relative;
  }
`

const Content = styled.div`
  padding: 0 30px;
  padding-top: 30px;
  padding-left: 280px;
  transition: transform 0.5s ease 0s;
  display: flex;
  > :first-child {
    flex: 1;
    margin-right: 20px;
  }
  > :last-child {
    position: sticky;
    right: 0;
    width: 190px;
  }
`

export default ({ data, pageContext: { next, prev } }) => {
  const { mdx } = data
  return (
    <ThemeProvider theme={THEME}>
      <Wrapper>
        <div>
          <Sidebar />
        </div>
        <Content>
          <div>
            <MDXProvider components={mdxComponents}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
          </div>
          <TableOfContent headings={mdx.headings} />
        </Content>
      </Wrapper>
    </ThemeProvider>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        slug
        title
      }
      headings {
        value
        depth
      }
      body
    }
  }
`
